import { Divider } from "@nextui-org/divider";
import { FaLinkedin } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="mt-auto">
      <Divider />
      <div className="flex items-center justify-center gap-2 mt-3">
        <a
          href="https://www.linkedin.com/in/alexandre-florent-624a84211/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-400 transition-colors hover:text-gray-600"
        >
          <FaLinkedin size={16} />
        </a>
        <span className="text-[10px] text-gray-400">•</span>
        <span className="text-[10px] text-gray-400">
          All rights reserved • Alexandre Florent {new Date().getFullYear()}
        </span>
      </div>
    </footer>
  );
};

export default Footer;
